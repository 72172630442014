import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { red,blue, yellow } from '../../design/colors/brand'
import { borderRadius } from '../../design/spacing/margin'
import { breakpointLg , breakpointSm } from '../../design/responsive/breakpoint'
const LockBannerContainer = styled.div`
    margin: 4rem 0;
    font-weight: bold;
    color: white;
    min-height: 130px;
    .number {
        font-size: 2.6rem;
        color: ${yellow}
    }
    .text-yellow {
        color: ${yellow}
    }
    &.large-size {
        .actions {
            margin-top: 1rem;
            flex-direction: column;
            .btn {
                width: 248px;
                margin: 0 0 1rem;
                padding: 1.043rem 2.5rem;
            }
        }
        .title {
            padding-top: 1.8rem;
           
        }
        .number {
            font-size: 3.6rem;
        }
        .lock-banner-right {
            max-width: 400px;
            flex: 0 0 400px;
            padding: 3rem 2rem 2rem 2rem;
        }
        .text {
            max-width: 100%;
            margin-bottom: 1rem;
            align-items center;
            position: relative;
            display: inline-flex;
            &.text-yellow {
                font-size: 2rem;
                &:after {
                    bottom: -1.5rem;
                }
            }
            &:after {
                content: "";
                left: 0;
                height: 2px;
                background: white;
                bottom: -1rem;
                width: 100%;
                position: absolute;
            }
        }
        .lock-banner-left {
            padding: 2rem 14rem 2rem 3.5rem;
            text-align: left;
            flex: 1;
            max-width: none;
            &:before {
                border-top: 130px solid ${red};
                border-bottom: 130px solid transparent;
            }
        }
        
    }
    @media (max-width: ${breakpointLg}) {

        &.large-size {
            .container {
                padding: 0 1rem;
            }
            .flex-rows {
                flex-direction: column
            }
            .actions {
                flex-direction: row;
                position: relative;
                padding-bottom: 4rem;
                .f-14 {
                   position: absolute;
                   bottom: 0;
                   width: 100%;
                   left:0;
                   text-align: center;
                }
                .btn {
                    width: auto;
                    margin: 0 0.5rem;
                    flex: 1;
                }
            }
          
            .lock-banner-left {
                padding: 0;
                border-radius: ${borderRadius} ${borderRadius} 0 0;
                max-width: 100%;
                flex: 1;
                &:before {
                    display: none
                }
                padding: 2rem 2rem 3rem;
            }
            .title {
                &:after {
                    width: 100%;
                }
            }
            .text {
                justify-content: flex-start;
            }
            .lock-banner-right {
                display: block!important;
                padding: 3rem 2rem 3rem;
                max-width: 100%;
                flex: 1;
                 border-radius:  0 0 ${borderRadius} ${borderRadius};
                 > .f-16 {
                    margin: 0 auto 2rem;
                    max-width: 268px
                 }
            }
        }
       
    }
    @media (max-width: ${breakpointSm}) {
        .container {
            padding: 0;
        }
        &:not(.large-size) {
            margin: 0;
        }
      
        min-height: 0px;
        .number {
            font-size: 3.6rem;
        }
       
    }

`;
const Left = styled.div`
   padding: 1.5rem 4.8rem 1.5rem 0;
   position: relative;
   max-width: 244px;
   flex: 0 0 244px;
   text-align: center;
   font-size: 1.4rem;
   background: ${red};
   overflow: hidden;
   border-radius: ${borderRadius}   0 0 ${borderRadius};
   &:before {
    border-width: 10px;
    border-color: ${red} ${blue} ${blue}; ${red};
    border-style: solid;
    content: "";
    position: absolute;
    right: 0;
    height: 100%;
    border-top: 65px solid ${red};
    border-left: 30px solid ${red};
    border-bottom: 65px solid transparent;
    border-right: 30px solid transparent;
    background: ${blue};
    position: absolute;
    top: 0;
   }
   .text {
        margin: auto;
        max-width: 7.5rem;
    }
    .des {
        font-weight: normal;
    }
    @media (max-width: ${breakpointSm}) {
        max-width: 100%;
        border-radius: 0;
        padding: 1.2rem 0;
        font-size: 1.4rem;
      
        .number {
            display: block;
        }
        flex: 1;
        &:before {
            display: none;
        }
        .text {
            max-width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

const Right = styled.div`
    padding: 2rem 0 1.5rem 0;
    background: ${blue};
    flex: 1;
    border-radius: 0 ${borderRadius}  ${borderRadius} 0;
    .actions {
        margin-top: 2rem;
        .btn {
            padding: 1.543rem 2.5rem;
            width: 220px;
            border-radius: 1rem;
            font-size: 1.2rem;
        }
        a:not(.btn) {
            color: #3CB1FF;
            &:hover {
                color: white;
            }
        }
        .btn ,.f-14{
            margin: 0 1rem;
        }
    }
    @media (max-width: ${breakpointLg}) {
        .actions {
            .btn {
                width: auto;
                padding: 1.543rem 1.5rem;
            }
        }
    }
`;
const LockBanner = (props: any) => {
    const className = props.className ?  props.className : ''
    const LargeSize = props.sizeLarge ?  'large-size' : ''
    const title = props.title
    const text = props.text
  return (
    <LockBannerContainer className={'lock-banner ' + LargeSize + ' ' + className  }   >
        <div className='container'> 
            <div className='d-flex flex-rows'>
                <Left className='lock-banner-left'>
                    {
                        props.showVideoRemain ? (
                            <div className='text text-yellow'>
                               NO VIDEO PLAYS REMAINING
                            </div>
                        ) : null
                            
                    }
                    {
                        !props.showVideoRemain ? (
                            <div className='text'>
                                <span>You have</span>
                                    <div className='number mx-2'>5</div>
                                <span>video plays remaining</span>
                            </div>
                        ) : null
                            
                    }
                    {
                        title ? (
                            <h4 className='title mb-3'>{title}</h4>
                        ) : null
                        
                    }
                    {
                        text ? (
                            <div className='des f-16'>{text}</div>
                        ) : null
                        
                    }
                </Left>
                <Right className='lock-banner-right text-center d-none d-md-block'>
                    <div className='f-16'>Register and access videos and daily live streams.</div>
                    <div className='actions d-flex align-items-center justify-content-center'>
                        <Link to='' className='btn btn-primary'>REGISTER NOW</Link>
                        <Link to='' className='btn btn-outline-white'>FIND OUT MORE</Link>
                        <div className='f-14'>Already a member?   <Link to='' className='text-decoration-none'>Login</Link></div>
                    </div>
                </Right>
            </div>
        </div>
    </LockBannerContainer> 
  )
}

export default LockBanner
