import * as React from 'react'
import styled from 'styled-components'
const PersonWrap = styled.div`
    &.vertical {
        .d-flex {
            flex-direction: column;
        }
        .person-image {
            margin: 0 0 2rem 0!important;
        }
    }
    &.lg {
        font-weight: bold;
        font-size: 1.6rem;
        .person-image {
            border-width: .4rem;
            width: 12.4rem;
            height: 12.4rem;
        }
    }
    &.sm {
        font-weight: bold;
        font-size: 2.4rem;
        .person-image {
            border-width: .4rem;
            width: 9rem;
            height: 9rem;
        }
       
    }
    .person-des {
        font-weight: normal;
        font-size: 2rem
    }
`;
const Image = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #E5E5E5;
    overflow: hidden;
`;
const Person = (props: any) => {
    const className = props.className ?  props.className : ''
    const vertical = props.vertical ?  'vertical' : ''
    const size = props.size ?  props.size : ''
  return (
    <PersonWrap className={'person ' + size + ' ' + className  + ' ' + vertical}   >
        <div className='d-flex align-items-center'>
            <Image className="person-image me-3 ratio ratio-1x1">
                <img src={props.image} alt='' />
            </Image>
            <div className="person-info">
                <div className="person-name">{props.name}</div>
            </div>
        </div>
        {
            props.text ? (
                <div className="person-des mt-4">{props.text}</div>
            ) : null
            
        }
       
    </PersonWrap> 
  )
}

export default Person
