import * as React from 'react'
import Slider from 'react-slick';
import styled from 'styled-components'
import {breakpointMd} from '../design/responsive/breakpoint'
import CardVideo from './common/card-video';

const Block = styled.section`
    .slick-slider {
        margin: 0 -1rem;
    }
    @media (max-width: 767px) {
        .card {
            width: 320px;
        }
        .description {
            margin-bottom: 2rem;
        }
    }
`;
const ListVideo = (props: any) => {
    const Videos = props.data.videos;
    const showDescription = props.ShowDescription ? true : false;
    const settings = {
        peed: 500,
        slidesToShow: showDescription ? 3 : 4,
        slidesToScroll: showDescription ? 3 : 4,
        arrows: false,
        rows: 1,
        autoplay: false,
        dots: false,
        cssEase: 'linear',
        autoplaySpeed: 5000, 
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  variableWidth: true
                }
              }
        ]
    };
    return (
        <>
            <Block className='list-videos list-video-slider-mobile'>
                <div className='container'>
                    <div className='row'>
                        {
                            showDescription ? (
                                <div className='col-md-3 description' >
                                    <div className='text mb-5 f-16'>
                                        <p>Explore our variety of on-demand fitness videos, from yoga to shadow boxing, cross-training and more. Browse the full range of videos.</p>
                                    </div>
                                    <a className='btn btn-outline-secondary'>Fitness videos</a>
                                </div>
                            ) : null
                        }
                        
                        <div className={ showDescription ? 'col-md-9' : ' col-md-12' }>
                        {
                            Videos.length ? (
                                <Slider {...settings}>
                                    {
                                    Videos.map((video: any)=>(
                                        <div className='px-3'>
                                            <CardVideo className='pb-0' data={video} key={video.id} /> 
                                        </div>
                                    ))
                                    }
                                </Slider>
                            ) : null
                        }
                        </div>
                    </div>
                
                </div>
            </Block>
        </>
    )
}

export default ListVideo
