import * as React from "react"
import Layout from "../components/common/layout"
import {  graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import Heading from "../components/common/heading"
import PartnerBanner from "../components/partner-banner"
import ListVideoSlider from "../components/list-videos-slider-mobile"
import LockBanner from "../components/common/lock-banner"
import Person from "../components/common//person" 
import Vimeo from '@u-wave/react-vimeo'
import SubMenu from "../components/common/sub-menu"
// markup 
const VideoHub = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const subMenu = _data.find((item: any) => item.block === 'sub-menu')
  const videos = _data.find((item: any) => item.block === 'videos')
  const dataPartnerBanner = _data.find((item: any) => item.block === 'partnerBanner')
  const link = {
    "link": "/video-main-category",
    "text": "View all",
  }
  return (
    <Layout>
      <Helmet
        title='Video detail'
      />
       <SubMenu data={subMenu.data[0]} />
      <div className="video-detail">
        <LockBanner />
        <div className="container">
           
            <div className="video-detail-block ">
                <div className=" mb-5 pb-3">
                    <div className="ratio ratio-16x9">
                        <Vimeo
                            showTitle={false}
                            showByline={false}
                            showPortrait= {false}
                            volume={1}
                            video='https://vimeo.com/69260479'
                        />
                    </div>
                </div>
               
                <div className="video-info mb-3">
                    <div className="row">
                        <div className="col-md-8 mb-3 mb-md-0">
                            <h3>Workout in slow mo</h3>
                            <div className="time text-muted f-16 mb-4">Beginner • 15 min</div>
                            <div className="video-text">
                                <p>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <Person className='mb-4' image='/images/caro.svg' size='sm' name='Nathan Pickering' text=' Nathan Pickering is a Live Life Get Active trainer from Macquarie Fields, NSW.' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Heading 
            link= {link}
            title='Cross-training videos' 
            background='bg-white'  
            />
        <ListVideoSlider  data={videos.data[0]}/>
        <LockBanner 
          title='Become a member today - it’s all FREE!'
          text='Get unlimited access to on-demand videos and LIVE online classes, your own personalised nutrition plan and invitations to FREE wellness offerings and competitions.'
          sizeLarge={true}
         />
        <PartnerBanner data={dataPartnerBanner.data[0]} background="bg-green" ></PartnerBanner>
        
    </div>  
     
    </Layout>
  )
}

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["video-detail"]}}) {
      nodes {
        jsonName
        data {
          block
          data {
            
            partner_banner {
              content
              link {
                id
                text
                url
              }
              logo
              partner_image
            }
            sub_menu{
              title
              links {
                id
                text
                link
                active
              }
            }
            videos {
              id
              title
              link
              image
              video {
                length
                level
                name
                tag
              }
            }
          }
        }
      }
    }
  }
`
export default VideoHub
